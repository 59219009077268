import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { Header, Category } from '../components/layout/index.js';
import { Section, Container } from '../components/common/index.js';

const DealsPage = ({ data }) => (
  <>
    <Header
      backgroundImage={data.file.childImageSharp.fluid}
      pageTitle="Super deals to save you money"
      // propertyName="Base Magnetic Island"
      // caption="Sunset Walking Tour"
    />
    <Helmet
      title="Backpacker travel deals in Australia and New Zealand"
      meta={[
        {
          name: 'description',
          content:
            'We all like to save some money. Find the latest deals and specials from basebackpackers and make your dollar stretch further when traveling AU and NZ.'
        }
      ]}
    />
    <Section>
      <Container>
        <h2>Wicked Deals</h2>
      </Container>
      <Container col="3" gap="2rem" mobcol="1fr">
        {data.deals.edges.map(({ node: product }) => (
          <Category
            title={product.title}
            fluid={product.featuredImage.fluid}
            key={product.id}
            ƒ
            alt={product.alt}
            url={`/deals/${product.slug}/`}
          />
        ))}
      </Container>
    </Section>
  </>
);

export default DealsPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "backpacker-destinations-australia-nz.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    deals: allDatoCmsProduct(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            alt
            fluid(
              maxWidth: 300
              maxHeight: 200
              imgixParams: {
                fm: "jpg"
                auto: "compress"
                fit: "crop"
                crop: "faces"
              }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
